module.exports = {
  'en': 'EN',
  'zh-mo': '中文',
  'btn.moreDetail': '了解更多',
  'introduction': '追你所求',
  'introduction.subtitle': '基源有限公司是澳門一家專注服務和產品行銷的公司, 為本土巿場引進國際優秀產品並向合作品牌提供最具巿場競爭力的產品銷售方案, 以解決本土需求. 我們的業務涵蓋檢測代理、諮詢及顧問、進出口貿易和會展服務',
  'services': '服務項目',
  'ourServices': '我們的服務',
  'mission': '使命',
  'description': '基源有限公司是澳門一家專注服務和產品行銷的公司, 為本土巿場引進國際優秀產品並向合作品牌提供最具巿場競爭力的產品銷售方案, 以解決本土需求. 我們的業務涵蓋檢測代理、諮詢及顧問、進出口貿易和會展服務',
  'selectLanguage': '選擇語言',
  'introduction.p1': '基源有限公司是澳門一家專注服務和產品行銷的公司, 為本土巿場引進國際優秀產品並向合作品牌提供最具巿場競爭力的產品銷售方案, 以解決本土需求. 我們的業務涵蓋檢測代理、諮詢及顧問、進出口貿易和會展服務',
  'introduction.p2': '我們珍惜產品, 因此我們很了解如何孕育產品在市場中的價值. 我們與優秀品牌合作, 協助定義產品價值, 市場定位及產品的通路. 基源有非常優秀的行銷業務團隊, 團隊深具品牌營銷經驗,與基源合作, 讓您的優質產品深入滲透市場, 擴大市場佔有率.',
  'contact': '想了解更多? 請聯繫我們',
  'service.logistics': '檢測代理',
  'service.logistics.detail': '我們為本澳客戶提供樣本外送檢測服務，務求在最短時間將樣品外送到客戶指定的檢測中心進行檢測，並提供報告追蹤、原廠專業人員解答等服務。',
  'service.marketing': '進出口貿易',
  'service.marketing.detail': '提供產品代理及營銷, 將產品帶給每一位有需求及所有值得擁有此產品的潛在消費者. 經驗豐富的基源行銷團隊憑藉精準的產品營銷計劃及活動, 致力協助我們的客戶提升市場占有率及銷售目標。',
  'service.exhibition': '會展服務',
  'service.exhibition.detail': '由專業的產品發表到普通的午宴，由公司的內部活動到國際會議。基源能依照您的需求幫忙籌劃、安排、並妥善執行您所重視的每個活。',
  'service.consult': '諮詢及顧問',
  'service.consult.detail': '我們珍惜產品並且了解本澳巿場. 我們清楚知道您產品的情況及實際的巿場需求. 運用基源的資源和能力, 我們會依照您的需要策劃相關活動。',
  'missions.title': '基源之使命、願景與核心價值',
  'mission.detail': '基源的使命是以澳門為中心，建構值得客戶及供應商信賴的服務和銷售通路，共創多贏局面。',
  'vision.detail': '我們的願景是能成為全球廠商欲開拓本土市場時，最佳的代理、經銷、或策略合作夥伴。',
  'value.detail': '我們的核心價值是提供全方位的支援，用高效能的服務來滿足客戶的需求。',
  'callUSNow': '您可以通過電話或電郵聯繫我們',
  'phone': '聯絡電話',
  'email': '電子郵件',
};
